// 发票支付
<template>
  <div id="pay" class="credit_pay">
    <div class="top_info">
      <div class="top_info_header">
        <div class="top_logo">
          <div :style="`background-image:url(${configInfo.main_site_logo})`" :onerror="defaultImg"></div>
        </div>
        <div class="top_info_progress flex_row_end_center">
          <div class="progress_item">
            <div class="progress flex_row_center_center">
              <span class="active">1</span>
              <div class="progress_line">
                <div :class="{ content: true, active: true, current: false }"></div>
              </div>
            </div>
            <p style="margin-left:-12px">{{L['确认订单']}}</p>
          </div>
          <div class="progress_item">
            <div class="progress flex_row_center_center">
              <span class="active">2</span>
              <div class="progress_line">
                <div :class="{ content: true, active: payComplete }"></div>
              </div>
            </div>
            <p>{{L['付款']}}</p>
          </div>
          <div class="progress_item" style="margin-left: -24px">
            <div class="progress flex_row_end_center">
              <span :class="{ active: payComplete }">3</span>
            </div>
            <p style="position:relative;left:12px;">{{L['支付成功']}}</p>
          </div>
        </div>
      </div>
    </div>
    <template v-for="(item, index) in detailInfo.data" :key="index">
      <div class="box8 flex-col">
        <div class="bd2 flex-col">
          <div class="group1 flex-row">
            <span class="word11">{{ item.storeName }}{{L['汇款信息']}}</span>
          </div>
          <div class="group2 flex-row">
            <div class="bd3 flex-col">
              <div class="main2 flex-row">
                <span class="word12">
                  {{L['信用账单号：']}}
                  <span>{{ item.billPaySn ? item.billPaySn : "—" }}</span>
                  <span v-if="item.billPaySn" class="iconfont iconzhuantimoban label2" :class="'billPaySn' + index"
                    @click="copy('.billPaySn' + index, item.billPaySn)"></span>
                </span>
              </div>
              <div class="main3 flex-row">
                <span class="word13">{{L['待结算金额：']}}</span>
                <span class="word14">¥{{ item.totalAmount ? item.totalAmount : 0 }}</span>
              </div>
              <div class="main1 flex-row">
                <span class="word15">
                  {{L['开户名称：']}}{{ item.companyName ? item.companyName : "—" }}
                  <span v-if="item.companyName" class="iconfont iconzhuantimoban label2" :class="'companyName' + index"
                    @click="copy('.companyName' + index, item.companyName)"></span>
                </span>
              </div>
              <div class="main4 flex-row">
                <span class="word16">
                  {{L['收款账户：']}}{{ item.bankAccount ? item.bankAccount : "—" }}
                  <span v-if="item.bankAccount" class="iconfont iconzhuantimoban label2" :class="'bankAccount' + index"
                    @click="copy('.bankAccount' + index, item.bankAccount)"></span>
                </span>
              </div>
              <span class="txt6">{{L['开户银行：']}}{{ item.bankName ? item.bankName : "—" }}</span>
            </div>
            <div class="txt7_box">
              <span class="txt7">{{L['汇款凭证：']}}</span>
              <div class="bd4 flex-col">
                <template v-if="!item.payVoucherUrl">
                  <span class="txt8">{{L['点击上传凭证']}}</span>
                  <input class="txt8_input" type="file" id="upload" accept="image/png,image/jpeg,image/jpg"
                    @change="uploadFile($event, index)" />
                </template>
                <template v-else>
                  <el-image class="txt8_img" :src="item.payVoucherUrl" :preview-src-list="[item.payVoucherUrl]">
                  </el-image>
                  <span v-if="!payComplete && !editPay" class="el-icon-error" @click="delImg(item)"></span>
                </template>
              </div>
            </div>
          </div>
          <div class="group3 flex-row">
            <span class="word17"> <strong>{{L['注意事项']}}</strong>{{L['（转账必读）']}}</span>
          </div>
          <span class="paragraph1">
            <div class="paragraph1_list">
              <span class="paragraph1_span">
                1.{{L['公司转账汇款时请将订单号']}}
                <span class="orderList" v-for="(items, indexs) in item.orderSns.split('.')" :key="indexs">
                  {{ indexs > 0 ? ',' : '' }}
                  <span class="orderSn" @click="orderSn(items)">{{ items }}</span>
                  <span class="iconfont iconzhuantimoban label2" :class="'orderSns' + index + indexs"
                    @click="copy('.orderSns' + index + indexs, items)"></span>
                </span>
              </span>
              {{L['填写至汇款单中“用途/备注/摘要”等栏']}}
            </div>
            <div class="paragraph1_list">
              2.{{L['务必保证转账金额与订单金额一致，请勿多转/少转或分次转账。']}}
            </div>
          </span>
        </div>
      </div>
    </template>
    <div class="pay_btn">
      <div v-if="!payComplete" class="main7 flex-col" @click="goPay">
        <span class="txt9">{{L['立即付款']}}</span>
      </div>
      <div v-else-if="editPay" class="main7 flex-col" @click="goPay">
        <span class="txt9">{{L['提交修改']}}</span>
      </div>
      <div v-else class="main7 flex-col">
        <span class="txt10">{{L['已完成汇款']}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import Clipboard from 'clipboard';
export default {
  name: "Pay",
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const L = proxy.$getCurLanguage()
    const route = useRoute();
    const router = useRouter();
    const paySn = ref(route.query.paySn);
    const payIds = ref(route.query.payIds);
    const configInfo = ref(store.state.configInfo);
    const detailInfo = reactive({ data: [] });
    const isPay = ref(false);
    const payComplete = ref(false); //支付成功
    const editPay = ref(false); //审核状态编辑

    const getDetail = () => {
      //获取信用账单详情
      let param = {
        billPaySn: paySn.value,
      };
      proxy
        .$get("v3/member/front/member/credit/bill/detail", param)
        .then((res) => {
          if (res.state == 200) {
            detailInfo.data.push(res.data);
            if (detailInfo.data[0].payVoucherUrl) {
              payComplete.value = true;
            }
          } else {
            ElMessage.error(res.msg);
            router.back();
          }
        });
    };

    const getMerge = () => {
      //获取合并结算账单详情
      let param = {
        payIds: payIds.value, //账单id
      };
      proxy
        .$post("v3/member/front/member/credit/bill/doSettlement", param)
        .then((res) => {
          if (res.state == 200) {
            detailInfo.data = res.data;
            if (detailInfo.data[0].payVoucherUrl) {
              payComplete.value = true;
            }
          } else {
            ElMessage.error(res.msg);
            router.back();
          }
        });
    };

    const uploadFile = (e, index) => {
      //上传凭证
      let file = e.target.files[0];
      if (file) {
        proxy
          .$post(
            "v3/oss/front/upload",
            {
              source: "paymentVoucher",
              file,
            },
            "form"
          )
          .then((res) => {
            if (res.state == 200) {
              detailInfo.data[index].payVoucher = res.data.path;
              detailInfo.data[index].payVoucherUrl = res.data.url;
              ElMessage.success(L['上传凭证成功']);
              if (payComplete.value && !editPay.value) {
                editPay.value = true;
              }
            } else {
              ElMessage.error(L['上传凭证失败']);
            }
          });
      }
    };

    const goPay = () => {
      let param = [];

      let errorIndex = null;
      detailInfo.data.map((item, index) => {
        if (!errorIndex && !item.payVoucher) {
          errorIndex = index;
        }
        let list = {
          billPaySn: item.billPaySn,
          payVoucher: item.payVoucher
        }
        param.push(list)
      })
      if (errorIndex != null) {
        ElMessage.warning(L['请上传'] + detailInfo.data[errorIndex].storeName + L['汇款信息的汇款凭证']);
        return;
      }

      //立即付款
      if (isPay.value) {
        return;
      }
      isPay.value = true;

      let config = {
        header: 'Content-Type:application/json'
      };
      proxy
        .$post("v3/member/front/member/credit/bill/confirmPayment", param, config)
        .then((res) => {
          if (res.state == 200) {
            if (!editPay.value) {
              ElMessage.success(L['付款成功']);
              payComplete.value = true;
            } else {
              ElMessage.success(L['编辑成功']);
              editPay.value = false;
            }
            setTimeout(() => {
              router.back();
            }, 1000)
          } else {
            isPay.value = false;
            ElMessage.error(L['付款失败']);
          }
        });
    };

    const delImg = (item) => {
      item.payVoucher = '';
      item.payVoucherUrl = '';
    };

    //复制
    const copy = (classname, val) => {
      var clipboard = new Clipboard(classname, {
        text: () => val
      });
      clipboard.on('success', e => {
        ElMessage.success(L['复制成功']);
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        ElMessage.error(L['您的浏览器不支持快捷复制']);
        clipboard.destroy()
      })
    };

    //订单详情
    const orderSn = (orderSn) => {
      router.push({
        path: '/member/order/info',
        query: {
          orderSn
        }
      })
    };

    onMounted(() => {
      if (route.query.paySn) {
        getDetail();
      }
      if (route.query.payIds) {
        getMerge();
      }
    });

    return {
      paySn,
      payIds,
      configInfo,
      detailInfo,
      isPay,
      payComplete,
      editPay,
      getDetail,
      getMerge,
      uploadFile,
      goPay,
      delImg,
      copy,
      orderSn,
      L
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/credit/pay.scss";
@import "@/style/base.scss";
</style>
<style lang="scss">
.credit_pay {
  .bd4 {
    .el-input {
      cursor: pointer;
      opacity: 0;
    }

    .el-input__inner {
      width: 179px;
      height: 106px;
      cursor: pointer;
    }
  }
}
</style>